<template>
    <div class="app-container">
        <el-row>
            <el-col class="filter-btn">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="addRole">{{ $t("roleManager.addRole")
                    }}</el-button>
            </el-col>
        </el-row>
        <el-table :data="roleList" border fit highlight-current-row stripe>
            <el-table-column type=index align="center" label="序号" width="95"></el-table-column>
            <el-table-column prop="roleName" label="角色名" width="451"></el-table-column>
            <el-table-column prop="roleNameDesc" label="描述"></el-table-column>
            <el-table-column label="操作" align="center" width="161">
                <template slot-scope="scope">
                    <div>
                        <el-button type="text" style="color:#409EFF ;"
                            @click="editRole(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" style="color: #E8331C;" @click="deleteRole(scope.row.id)">删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" width="728px">
            <template slot="title">
                <span>{{ rManagerList.dialogTitle }}</span>
            </template>
            <roleDialog @close="handleClose" :id="rManagerList.id" :dialogStatus="rManagerList.dialogStatus">
            </roleDialog>
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import roleDialog from "./roleDialog.vue"
export default {
    components: {
        roleDialog
    },
    data() {
        return {
            roleList: [],
            dialogVisible: false,
            rManagerList: {
                dialogStatus: 'add',
                dialogTitle: "添加角色",
            }
        }
    },
    created() {
        this.getRoleList()
    },
    methods: {
        getRoleList() {
            this.roleList = [{
                roleName: '角色名',
                roleNameDesc: '描述',
                id: 1
            }]
        },
        addRole() {
            console.log('111');
            this.dialogVisible = true
            this.rManagerList.id = null;
            this.rManagerList.dialogStatus = "add";
            this.rManagerList.dialogTitle = "添加角色";
        },
        editRole(index, row) {
            console.log(index, row.id);
            this.dialogVisible = true
            this.rManagerList.id = row.id
            this.rManagerList.dialogStatus = "edit";
            this.rManagerList.dialogTitle = "编辑角色";
        },
        deleteRole(id) {
            console.log(id);
            this.$confirm(
                this.$t("message.deleteConfirm"),
                this.$t("commons.warning"),
                {
                    confirmButtonText: this.$t("commons.confirm"),
                    cancelButtonText: this.$t("commons.cancel"),
                    type: "warning",
                }
            ).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        handleClose() {
            this.dialogVisible = false
        }
    }
}
</script>
<style lang="less" scoped>
.filter-btn {
    margin-bottom: 42px;
}
</style>