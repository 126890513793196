<template>
    <div class="role-manager-dialog">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
            <el-row :gutter="48">
                <el-col :span="24">
                    <el-form-item label="角色名" prop="roleName">
                        <el-input v-model="ruleForm.roleName" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="48">
                <el-col :span="24">
                    <el-form-item label="描述">
                        <el-input v-model="ruleForm.roleDescribe" placeholder="请输入"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item align="right" style="margin-top: 120px;">
                <el-button type="plain" @click="clickStep('close')">取消</el-button>
                <el-button type="primary" @click="clickStep('save')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
    },
    data() {
        return {
            ruleForm:{
                roleName:'',
                roleDescribe: ''
            },
            rules: {
                roleName: [
                    { required: true, message: '必填项', trigger: 'change' }
                ],
            },
        }
    },
    methods: {
        clickStep(type) {
            if(type == 'close') {
                this.$emit("close", false);
            } else if(type == 'save') {
                console.log('111');
                console.log(this.currentState,'状态');
                console.log(this.id,'id');
            }
        }
    },
    watch: {
        id: {
            handler(id) {
                if (!id) return;
                // this.getMeetingDetail(id);
            },
            immediate: true,
        },
        dialogStatus: {
            handler(val) {
                this.currentState = val;
            },
            immediate: true,
        },
    }
}

</script>

<style lang="less" scoped>
.role-manager-dialog {
    height: 348px;
}
</style>