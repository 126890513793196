var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "filter-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-plus",
                  },
                  on: { click: _vm.addRole },
                },
                [_vm._v(_vm._s(_vm.$t("roleManager.addRole")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.roleList,
            border: "",
            fit: "",
            "highlight-current-row": "",
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: "center",
              label: "序号",
              width: "95",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "roleName", label: "角色名", width: "451" },
          }),
          _c("el-table-column", {
            attrs: { prop: "roleNameDesc", label: "描述" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "161" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#409EFF" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editRole(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#E8331C" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRole(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            width: "728px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.rManagerList.dialogTitle))]),
          ]),
          _c("roleDialog", {
            attrs: {
              id: _vm.rManagerList.id,
              dialogStatus: _vm.rManagerList.dialogStatus,
            },
            on: { close: _vm.handleClose },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }