var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "role-manager-dialog" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色名", prop: "roleName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.roleName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "roleName", $$v)
                          },
                          expression: "ruleForm.roleName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.roleDescribe,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "roleDescribe", $$v)
                          },
                          expression: "ruleForm.roleDescribe",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "120px" },
              attrs: { align: "right" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "plain" },
                  on: {
                    click: function ($event) {
                      return _vm.clickStep("close")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.clickStep("save")
                    },
                  },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }